<template>
  <div class="header_li_wrap">
    <div class="header_li_right">
      <div class="flex_box">
        <div class="flex_box_left">
          <div v-for="item in topHalf" :key="item.code" :class="[Id == item.id ? 'active' : '']" class="header_li_right_item" :style="`cursor:pointer`" @click="pushRouter(item)" id="active" style="padding: 0 15px;">
            <p>{{item.categoryName}}</p>
            <img src="./picture/active.png" :style="Id == item.id ? '' : 'visibility: hidden'" />
          </div>
        </div>
      </div>

      <div class="logo_img">
        <div><img :src="webData.siteLogo ? webData.siteLogo.link : './picture/logo.png '" alt="" style="width: 56px;height:54px;object-fit: cover;"></div>
        <div class="text">英大招投标集团</div>
      </div>

      <div class="flex_box">
        <div class="flex_box_right">
          <div v-for="item in bottomHalf" :key="item.code" :class="[Id == item.id ? 'active' : '']" class="header_li_right_item" :style="`cursor:pointer`" @click="pushRouter(item)" id="active" style="padding: 0 15px;">
            <p>{{item.categoryName}}</p>
            <img src="./picture/active.png" :style="Id == item.id ? '' : 'visibility: hidden'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imgData from "@/data.js";
import { reactive, computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { mainStore } from "@/store";
import { storeToRefs } from "pinia";
import { getAllChildrenNavWithParentId } from "@/api/newApi/newApi.js";
export default {
  props: {
    webTitle: String,
  },
  setup() {
    const store = mainStore();
    const webData = computed(() => storeToRefs(store).webData)
    const activePageUrl = computed(() => {
      return storeToRefs(store).activePageUrl;
    });

    const listData = computed(() => storeToRefs(store).navBar);
    const length = computed(() => Math.ceil(listData.value.value.length / 2));
    const topHalf = computed(() => Array.isArray(listData.value.value) ? listData.value.value.slice(0, length.value) : []);
    const bottomHalf = computed(() => Array.isArray(listData.value.value) ? listData.value.value.slice(length.value) : []);

    const router = useRouter();
    const goToIndex = () => {
      store.setactivePageUrl("/index");
      router.push({ path: "/index" });
    };
    const Id = ref('1562270204717109249')
    const pushRouter = (item) => {
      const activeElements = document.querySelectorAll("#active")
      activeElements.forEach((el) => el.classList.remove('.active'));
      const { categoryType, link, id, navGroup } = item;
      Id.value = id
      sessionStorage.setItem('Ids', id)
      if (categoryType == 1 || categoryType == 2) {
        const path = link == "/" ? "/index" : link;
        router.push({ path: path, query: { Id: id, navGroup: navGroup } });
        store.setactivePageUrl(path);
      } else {
        window.open(link, "_blank");
      }
    };
    const idValue = ref('')
    const navGroup = ref('')
    onMounted(async () => {
      Id.value = sessionStorage.getItem('Ids')
      idValue.value = router.currentRoute.value.query.Id;
      navGroup.value = router.currentRoute.value.query.navGroup;
      const contentNav = {}
      const query = {}
      contentNav.status = 1
      contentNav.id = idValue.value
      contentNav.navGroup = navGroup.value ? navGroup.value : ''
      query.current = 1
      query.size = 10
      const res = await getAllChildrenNavWithParentId(contentNav, query)
      console.log(res, 'navGroup');
    })
    const data = reactive({
      listData,
      activePageUrl,
      webData
    });
    return {
      idValue,
      navGroup,
      ...data,
      ...imgData,
      pushRouter,
      Id,
      activePage: "/index",
      haveToken: false,
      goToIndex,
      topHalf,
      bottomHalf
    };
  },
  created() {
    let href = window.location.href;
    let origin = window.location.origin + "/";
    this.activePage =
      href.replace(origin, "") == "BiddingInfo"
        ? ""
        : "/" + href.replace(origin, "");

    let arr = document.cookie.split(";");
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].indexOf("saber-access-token") !== -1) {
        this.haveToken = true;
      }
    }
  },
  methods: {
    goToLogin() {
      window.open(process.env.VUE_APP_SEVERCE_PLATFORM, "_blank");
    },
    goToRegister() {
      window.open(process.env.VUE_APP_SEVERCE_REGISTER, "_blank");
    },
  },
};
</script>

<style scoped>
@import url("./Header.css");
</style>
<template>
  <div class="left_nav_wrap">
    <div class="wrap_box">
      <!-- <div class="left_nav_top">
        <img :src="leftNavImg"
             style="width: 100%" />
      </div> -->
      <div class="left_nav_content">
        <!-- <div class="left_nav_item"
             @click="goToServicePlatform()">
          <img :src="leftNavIconOfTenderee"
               style="width: 64%" />
        </div>
        <div class="left_nav_item"
             @click="goToServicePlatform()">
          <img :src="leftNavIconOfsupplier"
               style="width: 65%" />
        </div>
        <div class="left_nav_item"
             @click="goToServicePlatform()">
          <img :src="leftNavIconOfAgency"
               style="width: 80%" />
        </div>
        <div class="left_nav_item"
             @click="goToLogin()">
          <img :src="leftNavIconOfLogin"
               style="width: 74%" />
        </div>
        <div class="left_nav_item"
             @click="goToExpertReview()">
          <img :src="leftNavIconOfRegiter"
               style="width: 74%" />
        </div> -->

        <div class="left_nav_item"
             v-for="item in leftNav"
             :key="item.id"
             @click="goToUrl(item.url)">
          <img :src="item.attach.link"
               style="width: 100%; height: 100%; display:block;" />
        </div>

        <!-- <div class="left_nav_item" @click="goToLogin()">
          <img :src="leftNavIconOfLogin" style="width: 74%" />
        </div> -->
        <!-- <div class="left_nav_item" @click="goToExpertReview()">
          <img :src="leftNavIconOfExpert" style="width: 74%" />
        </div> -->

        <!-- <div class="left_nav_item"
             @click="goToMall()">
          <img :src="leftNavIconOfDirect"
               style="width: 50%" />
        </div> -->
      </div>
    </div>
  </div>
</template>


<script>
import imgData from "@/data.js";
import { reactive, computed } from "vue";
import { mainStore } from "@/store";
import { storeToRefs } from "pinia";

export default {
  setup() {
    const store = mainStore();
    const leftNav = computed(() => storeToRefs(store).leftNav)

    const data = reactive({
      leftNav
    });
    return {
      ...data,
      ...imgData,
    };
  },
  methods: {

    goToUrl(url) {
      location.href = "https://" + url;
      // window.open("https://" + url, "_blank");
    },

    goToServicePlatform() {
      location.href = process.env.VUE_APP_SEVERCE_PLATFORM;
    },
    goToExpertReview() {
      /* 评审 */
      // location.href = process.env.VUE_APP_EXPERTREVIEW;

      /* 服务平台 */
      // location.href = process.env.VUE_APP_SEVERCE_PLATFORM;

      /* 注册 */
      location.href = process.env.VUE_APP_EXPERT_REGISTER;
    },
    goToLogin() {
      /* 登录 */
      // location.href = process.env.VUE_APP_EXPERT_LOGIN;

      /* 服务平台 */
      location.href = process.env.VUE_APP_SEVERCE_PLATFORM;
    },
    goToMall() {
      location.href = process.env.VUE_APP_MALL;
    },
  },
};
</script>

<style >
@import url("./LeftNav.css");
</style>
<template>
  <div style="width: 100%;min-width: 1297px;display: flex;flex-direction: column;align-items: center;">
    <Header :webTitle="webTitle" />
    <!-- <Banner :bannerUrl="bannerUrl" :webTitle="webTitle" :content="content" v-if="!hideBanner" /> -->
    <!-- :subTilte="subTilte" 原本在banner标签里 -->
    <!-- <LeftNav v-if="!hideLeft" /> -->
    <!-- <RightNav /> -->
    <slot />
    <NavFooter :webTitle="webTitle" />
  </div>
</template>

<script>
import Header from "./components/Header/Header";
import LeftNav from "./components/LeftNav/LeftNav";
import Banner from "./components/Banner/Banner";
import NavFooter from "./components/Footer/Footer";
import RightNav from "./components/RightNav/RightNav";
import { getWebTitle } from "@/api/cmsSite/cmsSite";
import { mainStore } from "@/store";
import { ref } from "vue";
export default {
  props: ["bannerUrl", "subTilte", "content", "hideLeft", "hideBanner"],
  components: { Header, LeftNav, Banner, NavFooter, RightNav },
  setup() {
    const store = mainStore();
    const webTitle = ref("");
    const aaa = store.getWebData;
    aaa();
    getWebTitle().then((res) => {
      webTitle.value = res.data;
    });
    return {
      webTitle,
    };
  },
  methods: {
    toHelpCenter() {
      this.$parent.toHelpCenter();
    },
  },
};
</script>
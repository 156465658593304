<template>
  <div class="banner_wrap">
    <div class="banner_wrap_content" style="position: relative;">
      <el-carousel height="550px" :interval="5000" v-if="AdDataValue[0]">
        <el-carousel-item v-for="(item, index) in AdDataValue" :key="item.id">
          <img style="width: 100%;height: 100%;" :src="item.attachList[0].link" />
          <div class="pic_text" v-if="index == 0">
            <div class="text_essential" style="font-family: 'CN-Regular';">企业文化</div>
            <div class="text_special">儒风商才 义利合一</div>
            <div class="text_essential" style="margin-left: 15px;font-family: 'CN-Regular';">经营理念</div>
            <div class="text_special_small">忠信敬业 创新进取 崇智求真</div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import {ref,onBeforeMount } from "vue";
import {getAllAd} from "@/api/index.js"
export default {
  props: {
    bannerUrl: String,
    subTilte: String,
    content: String,
    webTitle: String,
  },
  setup() {
    //const AdData3 = computed(() => storeToRefs(mainStore()).AdData3);
    const AdDataValue=ref([])
    const AdData3 = async()=>{
      const res= await getAllAd()
      for(let i=0;i<res.data.length;i++){
        if(res.data[i].adType == 3){
          AdDataValue.value.push(res.data[i])
           console.log(AdDataValue,'AdDataValue');
        }
      }
    }
    onBeforeMount(async()=>{
      await AdData3()
    })

    return {
      AdData3,
      AdDataValue
    };
  },
};
</script>

<style >
@import url("./Banner.css");
</style>